import './style/menu.css';

import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useContext, useEffect, useState } from 'react';

import DesktopMenu from './components/desktop-menu';
import MobileLanguageSelector from './components/mobile-language-selector';
import MobileMenu from './components/mobile-menu';
import { ModalContext } from '../../contexts/ModalContext';
import { StaticImage } from 'gatsby-plugin-image';

const Menu = ({ headerImg, darkTheme }) => {
  const { t } = useTranslation();
  const { setIsDonateModalOpen } = useContext(ModalContext);
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const [isDesktopView, setIsDesktopView] = useState(true);
  const [bgColor, setBgColor] = useState({
    backgroundColor: 'transparent'
  });

  const switchMobileMenu = () => {
    setIsMobileMenu(!isMobileMenu);
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (window.innerWidth < 600) {
      setIsDesktopView(false);
    } else {
      setIsDesktopView(true);
    }

    const handleResize = () => {
      if (window.innerWidth > 1110) {
        setIsMobileMenu(false);
      }

      if (window.innerWidth < 600) {
        setIsDesktopView(false);
      } else {
        setIsDesktopView(true);
      }
    };

    const handleScroll = () => {
      if (window.pageYOffset === 0) {
        setBgColor({ backgroundColor: 'transparent' });
      } else {
        darkTheme
          ? setBgColor({ backgroundColor: '#080E14' })
          : setBgColor({ backgroundColor: 'white' });
      }
      setIsMobileMenu(false);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [darkTheme]);

  const themeClass = darkTheme ? 'nav-menu-dark-theme' : 'nav-menu-white-theme';

  return (
    <>
      <div className="contact-ctn">
        <div className="container contact-inner-ctn">
          <div className="contact-inner-left-ctn">
            <a href="tel:+48 668 574 097">
              <StaticImage
                src="../../images/header/telephone.png"
                alt="Telephone icon"
                placeholder="none"
                loading="lazy"
                className="icon"
              />
              {isDesktopView && (
                <span className="mg-left">{t`menu.call-to-us`}</span>
              )}
            </a>
            <p style={{ margin: '0 1rem' }}> | </p>
            <a href="mailto:hello@innovationshub.pl">
              <StaticImage
                src="../../images/header/mail-icon.png"
                alt="Telephone icon"
                placeholder="none"
                loading="lazy"
                className="icon"
              />
              {isDesktopView && (
                <span className="mg-left">{t`menu.write-to-us`}</span>
              )}
            </a>
            <p style={{ margin: '0 1rem' }}> | </p>
            <a onClick={() => setIsDonateModalOpen(true)}>
              <StaticImage
                src="../../images/header/donate.png"
                alt="Donate icon"
                placeholder="none"
                loading="lazy"
                className="icon"
              />
              {isDesktopView && (
                <span className="mg-left">{t`menu.donate`}</span>
              )}
            </a>
          </div>
          <MobileLanguageSelector darkTheme={darkTheme} />
        </div>
      </div>
      <nav className={`nav-menu ${themeClass}`} style={bgColor}>
        <div className="container nav-menu-container">
          <div className="nav-logo-container">
            <Link to="/">
              {darkTheme === true ? (
                <StaticImage
                  src="../../images/header/inhub-logo-white.png"
                  alt="MedBiz Innovations - MedTech program"
                  className="nav-logo-img"
                  placeholder="none"
                  loading="eager"
                  quality={100}
                />
              ) : (
                <StaticImage
                  src="../../images/header/dark_logo.png"
                  alt="MedBiz Innovations - MedTech program"
                  className="nav-logo-img"
                  placeholder="tracedSVG"
                  loading="eager"
                  quality={100}
                />
              )}
            </Link>
          </div>

          <DesktopMenu darkTheme={darkTheme} />

          <button
            className="nav-menu-burger-container"
            onClick={() => switchMobileMenu()}>
            {darkTheme ? (
              <StaticImage
                src="../../images/header/burger-menu-white.png"
                alt="Burger menu"
                className="nav-menu-burger-icon"
              />
            ) : (
              <StaticImage
                src="../../images/header/burger-menu.png"
                alt="Burger menu"
                className="nav-menu-burger-icon"
              />
            )}
          </button>
        </div>
      </nav>

      {isMobileMenu && <MobileMenu darkTheme={darkTheme} />}

      {headerImg && <div className="header-img" id="page-top" />}
    </>
  );
};

export default Menu;
